<template>
  <div class="form-item" :class="{ 'form-item__open': open, 'form-item__opened': opened }">
    <button type="button" class="form-item__header" @click="open = opened || !open">
      <IconComponent v-if="!opened" name="arrow-down" />
      <span class="form-item__title">{{ title }}</span>
    </button>
    <div class="form-item__body" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "FormItemComponent",
  components: { IconComponent },
  props: {
    title: String,
    opened: Boolean,
  },
  data() {
    return {
      open: true,
    };
  },
};
</script>

<style lang="stylus">
.form-item {
  display grid
  align-items start
  background: var(--white);
  border-radius: var(--big-radius);

  &__open &__header {
    .icon {
      transform rotate(180deg)
    }
  }

  &:not(&__opened) &__header {
    cursor pointer

    &:hover {
      color var(--blue)

      .icon svg path {
        fill var(--blue)
      }
    }
  }

  &__header {
    padding 20px
    display flex
    align-items center
    gap: 10px
    border none
    background none
    font-weight: 700;
    font-size: 1.125em
    line-height: 140%;

    .icon {
      width 16px
      height: 16px

      svg path {
        fill var(--body-color)
        transition var(--transition)
      }
    }
  }

  &__body {
    padding 0 20px 20px
    display flex
    flex-direction column
    gap: 20px
  }

  &__title {
    display: flex;
    align-items: center;
    text-align left
    transition color var(--transition)
  }
}
</style>
