<template>
  <div class="default-menu filter-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <span class="default-menu__title">Фильтр</span>
        <button type="button" class="default-menu__close" @click="$emit('close')">
          <IconComponent name="close-square" />
        </button>
      </div>
    </div>
    <div class="default-menu__body filter-menu__body">
      <CatalogFiltersComponent :is-filter-menu="true" />
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import CatalogFiltersComponent from "@/views/catalog/components/CatalogFiltersComponent.vue";

export default {
  name: "FilterMenu",
  components: { CatalogFiltersComponent, IconComponent },
};
</script>

<style lang="stylus">
.filter-menu {
  background var(--gray)

  &__body {
    padding-top 20px
  }
}
</style>
