<template>
  <div class="catalog-range-slider">
    <div class="catalog-range-slider__inputs">
      <div class="catalog-range-slider__input-wrap">
        <span>от</span>
        <InputComponent
          :min="minValue"
          :max="parseInt(max)"
          v-model="min"
          type="number"
          :placeholder="minValue"
          @blur="handleMinBlur"
        />
      </div>
      <div class="catalog-range-slider__input-wrap">
        <span>до</span>
        <InputComponent
          v-model="max"
          :min="parseInt(min)"
          :max="maxValue"
          type="number"
          @blur="handleMaxBlur"
          :placeholder="maxValue"
        />
      </div>
    </div>
    <MultiRangeSlider
      :min="minValue"
      :max="maxValue"
      :step="10"
      :minValue="parseInt(min)"
      :maxValue="parseInt(max)"
      ref="range-slider"
      @input="handleRangeSliderUpdate"
    />
  </div>
</template>

<script>
import MultiRangeSlider from "components/inputs/MultiRangeSliderComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "CatalogRangeSlider",
  props: {
    minValue: Number,
    maxValue: Number,
  },
  data() {
    return {
      min: undefined,
      max: undefined,
    };
  },
  mounted() {
    this.min = this.minValue;
    this.max = this.maxValue;
  },
  watch: {
    min() {
      this.$emit("change", { min: this.min, max: this.max });
    },
    max() {
      this.$emit("change", { min: this.min, max: this.max });
    },
  },
  methods: {
    handleMinBlur() {
      if (this.min < this.minValue) {
        this.min = this.minValue;
      } else if (this.min > this.max) {
        this.min = this.max;
      }
    },
    handleMaxBlur() {
      if (this.max < this.min) {
        this.max = this.min || 0;
      } else if (this.max > this.maxValue) {
        this.max = this.maxValue || 0;
      }
    },
    handleRangeSliderUpdate(e) {
      this.min = e.minValue || e.min;
      this.max = e.maxValue || e.max;
    },
  },
  components: { InputComponent, MultiRangeSlider },
};
</script>

<style lang="stylus">
.catalog-range-slider {
  display grid
  grid-gap 20px
  align-items start

  .range-slider {
    padding: 10px;
  }

  &__inputs {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 15px
    padding 0 15px

    .input__container {
      border-radius var(--big-radius)
      height 50px
    }

    .input__field {
      padding 10px 8px
      line-height: 20px;
      text-align center
    }
  }

  &__input-wrap {
    display flex
    align-items center
    gap: 10px
    color var(--dark-light)

    > span {
      font-size 0.875em
      line-height: 20px;
      flex-shrink 0
    }

    .input {
      flex-grow 1
    }
  }
}
</style>
