<template>
  <label
    class="check"
    :class="{
      'check--disabled': disabled,
      'check--error': error,
      'check--checked': isChecked,
      'check--indeterminate': indeterminate,
    }"
  >
    <span class="check__state">
      <IconComponent name="check" />
    </span>
    <input
      type="checkbox"
      :checked="isChecked"
      :disabled="disabled"
      :indeterminate="indeterminate"
      @change="toggleValue"
    />
    <slot />
  </label>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "CheckboxComponent",
  components: { IconComponent },
  props: {
    error: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
    checked: Boolean,
    value: [Array, Object, Boolean],
    item: Object,
  },
  computed: {
    isChecked() {
      return this.checked || (this.value && this.value.length && this.value.includes(this.item.id));
    },
  },
  methods: {
    toggleValue(e) {
      if (this.value) {
        let value = JSON.parse(JSON.stringify(this.value));
        if (value.includes(this.item.id)) {
          value.splice(value.indexOf(this.item.id), 1);
        } else {
          value.push(this.item.id);
        }
        this.$emit("input", value);
      }
      this.$emit("change", e.target.value);
    },
  },
};
</script>

<style lang="stylus">
.check {
  display flex
  gap 10px
  position relative
  cursor pointer
  align-items: flex-start;
  text-align: left;
  user-select none
  transition var(--transition)

  &:hover {
    color var(--blue)

    .check__state {
      border-color var(--blue)
    }
  }

  input {
    absolute left top
    visibility hidden
  }

  &--error {
    color var(--red) !important

    .check__state {
      border-color var(--red) !important
    }
  }

  &--disabled {
    color var(--gray)

    & .check__state {
      background: var(--gray);
      border: 1px solid var(--gray);
    }

    &.check--indeterminate
    &.check--checked {
      & .check__state {
        background: var(--gray);
        border: 1px solid var(--gray);
      }

      svg path {
        fill var(--white)
      }
    }
  }

  &--indeterminate:not(&.check--disabled)
  &--checked:not(&.check--disabled) {
    & .check__state {
      background var(--blue)
      border-color var(--blue)
    }
  }

  &--indeterminate:not(&.check--disabled) {
    & .check__state {
      height: 2px
    }
  }

  &__state {
    width 18px
    height 18px
    background: var(--white);
    border: 2px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 4px;
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    margin-top 3px
    transition var(--transition)

    .icon {
      width 100%
      height 100%
    }
  }
}
</style>
