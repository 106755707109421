<template>
  <div
    class="catalog-filter"
    :class="{
      'catalog-filter--menu': isFilterMenu || isCategoriesMenu,
      'catalog-filter--categories-menu': isCategoriesMenu,
    }"
  >
    <div class="catalog-filter__inner">
      <FormItemComponent v-if="!isCategoriesMenu" title="Цена">
        <div class="catalog-filter__price">
          <CatalogRangeSlider @change="priceChange" :min-value="minValue" :max-value="maxValue" />
        </div>
      </FormItemComponent>
      <!--      <FormItemComponent title="Размер">-->
      <!--        <SelectComponent v-model="form.size" :options="sizes" label-name="title" title="- Любой -" />-->
      <!--      </FormItemComponent>-->
      <!--      <FormItemComponent title="Пол">-->
      <!--        <div class="catalog-filter__checkboxes">-->
      <!--          <div class="catalog-filter__checkboxes-list">-->
      <!--            <CheckboxComponent v-model="form.gender" v-for="(item, i) in genders" :key="i" :item="item">-->
      <!--              <span> {{ item.title }} </span>-->
      <!--            </CheckboxComponent>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </FormItemComponent>-->
      <FormItemComponent v-if="!isCategoriesMenu && !isBrandPage" title="Бренд">
        <div class="catalog-filter__checkboxes">
          <!--          <InputComponent v-model="brandsSearch" placeholder="Поиск бренда" icon-position="left" />-->
          <div v-if="brands && brands.length" class="catalog-filter__checkboxes-list">
            <CheckboxComponent v-model="form.brand_id" v-for="(item, i) in brands" :key="i" :item="item">
              <span> {{ item.title }} </span>
            </CheckboxComponent>
          </div>
          <span v-else-if="brandsSearch">Ничего не найдено</span>
          <span v-else>Бренды отсутствуют</span>
        </div>
      </FormItemComponent>
      <FormItemComponent v-for="filter in filter_attributes" :key="filter.id" :title="filter.attribute.title">
        <div class="catalog-filter__checkboxes">
          <div
            v-if="filter.attribute && filter.attribute.values.length"
            class="catalog-filter__checkboxes-list"
          >
            <CheckboxComponent
              v-for="(value, i) in filter.attribute.values"
              :key="i"
              :item="value"
              :checked="isChecked(value.id, filter.attribute.id)"
              @change="toggleValue(value.id, filter.attribute.id)"
            >
              <span> {{ value.value }} </span>
            </CheckboxComponent>
          </div>
          <span v-else-if="brandsSearch">Ничего не найдено</span>
          <span v-else>Бренды отсутствуют</span>
        </div>
      </FormItemComponent>
      <!--      <FormItemComponent title="Скидки">-->
      <!--        <CheckboxComponent :checked="form.in_share" @change="form.in_share = !form.in_share">-->
      <!--          <span> Акции и скидки </span>-->
      <!--        </CheckboxComponent>-->
      <!--      </FormItemComponent>-->
      <button
        v-if="JSON.stringify(this.form) !== JSON.stringify(this.defaultForm)"
        @click="reset"
        type="button"
        class="btn btn--md btn--red"
      >
        Сбросить
      </button>
    </div>
  </div>
</template>

<script>
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import CatalogRangeSlider from "./CatalogRangeSlider.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import FormItemComponent from "components/FormItemComponent.vue";

// переделать численные параметры
function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

export default {
  name: "CatalogFiltersComponent",
  props: {
    loading: Boolean,
    isFilterMenu: Boolean,
    isCategoriesMenu: Boolean,
  },
  data() {
    return {
      isBrandPage: false,
      active: undefined,
      activeChild: undefined,
      inputDebounceTimeout: null,
      minValue: 0,
      maxValue: 10,
      brandsSearch: "",
      params: {},
      form: {
        price_from: 0,
        price_to: 10,
        in_share: false,
        brand_id: [],
        size: undefined,
        filter_attribute: [],
      },
      defaultForm: {
        price_from: 0,
        price_to: 10,
        in_share: false,
        brand_id: [],
        size: undefined,
        filter_attribute: [],
      },
      genders: [
        {
          id: this.$store.state._types.MALE,
          title: "Мужской",
        },
        {
          id: this.$store.state._types.FEMALE,
          title: "Женский",
        },
      ],
      sizes: [
        {
          id: 1,
          title: "S",
        },
        {
          id: 2,
          title: "M",
        },
        {
          id: 3,
          title: "L",
        },
        {
          id: 4,
          title: "XL",
        },
      ],
    };
  },
  watch: {
    form: {
      handler() {
        if (this.inputDebounceTimeout) {
          clearTimeout(this.inputDebounceTimeout);
        }
        this.inputDebounceTimeout = setTimeout(() => {
          this.setUrlParams();
          this.setVariables();
          // this.$emit("filter", this.form);
        }, 500);
      },
      deep: true,
    },
  },
  created() {
    if (this.isFilterMenu) {
      this.params = this.$store.state.variables;
    } else this.params = this.parseParams(this.$route.query);
    let min = this.$store.state.catalog_page.products_min_max_price?.min;
    let max = this.$store.state.catalog_page.products_min_max_price?.max;
    this.minValue = this.form.price_from = this.defaultForm.price_from = min || 0;
    this.maxValue = this.form.price_to = this.defaultForm.price_to = max || 0;
    this.setForm();
  },
  mounted() {
    this.isBrandPage = new URLSearchParams(window.location.search).get("brands_item_id");
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    brands() {
      if (this.$store.state.global.brands) {
        return this.$store.state.global.brands.filter((item) =>
          item.title.toLowerCase().includes(this.brandsSearch.toLowerCase())
        );
      }
      return [];
    },
    filter_attributes() {
      return this.$store.state.share_page.filter_attributes;
    },
  },
  methods: {
    isChecked(valId, attrId) {
      let index = this.form.filter_attribute.findIndex((a) => a.attribute === attrId);
      if (index !== -1) {
        return this.form.filter_attribute[index].attribute_value.includes(valId);
      }
      return false;
    },
    toggleValue(valId, attrId) {
      let index = this.form.filter_attribute.findIndex((a) => a.attribute === attrId);
      if (index !== -1) {
        let i = this.form.filter_attribute[index].attribute_value.indexOf(valId);
        if (i !== -1) {
          this.form.filter_attribute[index].attribute_value.splice(i, 1);
          if (!this.form.filter_attribute[index].attribute_value.length) {
            this.form.filter_attribute.splice(index, 1);
          }
        } else {
          this.form.filter_attribute[index].attribute_value.push(valId);
        }
      } else {
        this.form.filter_attribute.push({
          attribute: attrId,
          attribute_value: [valId],
        });
      }
    },
    setActive(id, active) {
      let el = this.$refs[`categoryChilds${id}`][0];
      if (id !== this[active]) {
        if (this[active]) {
          this.$refs[`categoryChilds${this[active]}`][0].style.height = 0; // закрываем предыдущий
        }
        this[active] = id;
        // el.style.height = el.scrollHeight + "px";
        el.style.height = "auto";
      } else {
        this[active] = undefined;
        el.style.height = 0;
      }
    },
    // isActive(category) {
    //   return (
    //     this.active === category.id ||
    //     (category.childs && category.childs.map((item) => item.id).includes(this.active))
    //   );
    // },
    setUrlParams() {
      const urlParams = new URLSearchParams();
      Object.keys(this.form).forEach((key) => {
        if (key === "brand_id" || key === "filter_attribute") {
          if (this.form[key] && this.form[key].length) {
            urlParams.set(key, JSON.stringify(this.form[key]));
          }
        } else if (this.form[key] !== undefined) urlParams.set(key, this.form[key]);
      });
      history.pushState({}, null, location.origin + location.pathname + "?" + urlParams.toString());
    },
    setForm() {
      if (this.params) {
        Object.keys(this.form).forEach((key) => {
          if (key === "brand_id" && key === "filter_attribute" && !Array.isArray(this.params[key])) {
            if (this.params[key]) this.form[key] = [this.params[key]];
          } else if (this.params[key]) this.form[key] = this.params[key];
        });
      }
    },
    setVariables() {
      this.$store.state.variables = JSON.parse(JSON.stringify(this.form));
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(this.defaultForm));
      this.$store.state.variables = {};
    },
    priceChange(range) {
      this.form.price_from = range.min;
      this.form.price_to = range.max;
    },
    parseParams,
  },
  components: {
    FormItemComponent,
    SelectComponent,
    InputComponent,
    CatalogRangeSlider,
    CheckboxComponent,
  },
};
</script>

<style lang="stylus">
.catalog-filter {
  &__inner {
    display grid
    align-items start
    grid-gap 10px
    border-radius: var(--main_radius);
  }

  &:not(&--menu) {
    +below(990px) {
      display none
    }
  }

  &--menu {
    .filter-item__header {
      font-size 1em
    }

    .check {
      font-size 0.875em
    }
  }

  &--categories-menu {
    .filter-item {
      border none
    }

    .filter-item__header {
      display none
    }

    .catalog-filter__categories {
      max-height none

      > li:not(:last-child) {
        border none
      }
    }
  }

  &__header {
    border-bottom: 1px solid var(--blue-500);
    display grid
    grid-gap 8px
    padding-bottom 16px
  }

  &__header-top {
    display flex
    align-items center
    justify-content space-between
  }

  &__title {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--body-color);
  }

  &__body {
    display flex
    flex-direction column
    grid-gap 16px

    &-container {
      display grid
      grid-gap 30px
    }
  }

  &__categories {
    display grid
    max-height 572px
    overflow-y auto

    > li {
      display grid

      &:not(:last-child) {
        border-bottom 1px solid var(--gray)
      }
    }
  }

  &__category {
    padding 10px 40px 10px 20px
    font-size: 0.875em
    line-height: 145%;
    gap 8px
    display flex
    align-items center
    justify-content space-between

    //&:hover {
    //  background var(--gray)
    //}

    &--active {
      color: var(--brown-light);

      .icon svg path {
        stroke: var(--brown-light);
      }
    }

    .icon {
      width 12px
      height 12px
    }

    > .btn {
      height 40px
      width 40px
      position absolute
      right 0
      top 0
      bottom 0
      border-radius 0

      &:hover {
        background var(--gray)
      }

      &.active {
        .icon {
          transition var(--transition)
          transform rotate(90deg)
        }
      }
    }

    &-childs {
      padding-left 10px
      height 0
      min-height 0
      overflow hidden
      transition var(--transition)
    }
  }

  &__price {
    display grid
    grid-gap 20px
  }

  &__checkboxes {
    .input {
      width 100%
      height 50px
      margin-bottom: 20px
    }

    &-list {
      display grid
      max-height 243px
      overflow-y auto
			scrollbar-width: thin
    }

    .check {
      padding 5px 0
    }
  }

  &__colors {
    display flex
    gap 12px
    flex-wrap wrap
    align-items flex-start

    li {
      display inline-flex
    }

    label {
      cursor pointer
      width 24px
      height 24px
      flex-shrink 0
      border-radius 100%
      outline 2px solid transparent
      transition outline-color var(--transition)

      &.active {
        outline-color var(--brown)
      }
    }
  }
}
</style>
