<template>
  <div class="page share-page page-sections__even">
    <BreadcrumbsComponent :links="links" :title="share.title" />
    <div class="page__inner">
      <SectionComponent>
        <ImgComponent v-if="share.head_img" :img="share.head_img" :lazy="false" class="share-page__img" />
        <BenefitsComponent class="share-page__benefits" border />
        <div v-show="share.description" class="share-page__description">
          {{ share.description }}
        </div>
        <div class="share-page__body">
          <div class="share-page__filter-mobile">
            <button @click="showFilterMenu" class="btn btn--md btn--gray-hollow">
              <IconComponent name="settings-2" />
              <span>Фильтр</span>
            </button>
          </div>
          <CatalogFiltersComponent @filter="filter" :loading="filterLoading" />
          <div v-if="showProducts" class="share-page__products-list">
            <ProductsComponent ref="productsList" :products="products.data" />
            <PaginationComponent :page="page" :first="first" :list="products" @change="paginate" />
          </div>
          <span v-else-if="$store.state.variables">По данному запросу ничего не найдено</span>
          <span v-else>В данной акции товары отсутвуют</span>
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import SHARE_PAGE from "@/graphql/pages/SharePage.graphql";
import BenefitsComponent from "components/BenefitsComponent.vue";
import ProductsComponent from "components/ProductsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PaginationComponent from "components/Pagination.vue";
import CatalogFiltersComponent from "../../catalog/components/CatalogFiltersComponent.vue";
import IconComponent from "components/IconComponent.vue";
import FilterMenu from "components/menus/components/FilterMenu.vue";

let _isMobile = false;
let _query = {};

function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}
export default {
  name: "SharePage",
  async asyncData({ apollo, store, route, isMobile }) {
    _isMobile = isMobile;
    _query = parseParams(route.query);
    await apollo.defaultClient
      .query({
        query: SHARE_PAGE,
        variables: {
          id: parseInt(route.params.id),
          share_id: parseInt(route.params.id),
          first: isMobile ? 12 : 11,
          parent: true,
          ..._query,
        },
      })
      .then(({ data }) => {
        store.state.share_page.share = data.share;
        store.state.share_page.products = data.products_paginate;
        store.state.share_page.filter_attributes = data.filter_attributes;
        store.state.catalog_page.products_min_max_price = data.products_min_max_price;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  data() {
    return {
      page: 1,
      first: this.$store.state.isMobile ? 12 : 11,
      paginateLoading: false,
      filterLoading: false,
      params: {},
    };
  },
  watch: {
    "$store.state.variables": {
      handler() {
        this.filter();
      },
      deep: true,
    },
  },
  computed: {
    share() {
      return this.$store.state.share_page.share || {};
    },
    products() {
      return this.$store.state.share_page.products || {};
    },
    links() {
      return [{ title: "Акции", route: { name: "shares" } }];
    },
    showProducts() {
      return this.products && this.products.data && this.products.data.length;
    },
  },
  methods: {
    showFilterMenu() {
      this.$store.state._menus.push({
        component: FilterMenu,
        options: {},
      });
    },
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async filter() {
      this.page = 1;
      this.filterLoading = true;
      await this.load();
      this.scrollUp();
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: SHARE_PAGE,
          variables: {
            id: this.share.id,
            share_id: this.share.id,
            first: this.first,
            page: this.page,
            ...this.params,
            ...this.$store.state.variables,
            in_share: true
          },
        })
        .then(({ data }) => {
          this.filterLoading = false;
          this.paginateLoading = false;
          this.$store.state.share_page.products = data.products_paginate;
        })
        .catch(() => {
          this.filterLoading = false;
          this.paginateLoading = false;
        });
    },
    scrollUp() {
      if (this.$refs.productsList && window.scrollY > this.$refs.productsList.$el.offsetTop) {
        window.scrollTo({ top: this.$refs.productsList.$el.offsetTop - 30 });
      }
    },
  },
  components: {
    IconComponent,
    CatalogFiltersComponent,
    PaginationComponent,
    BreadcrumbsComponent,
    SectionComponent,
    ProductsComponent,
    BenefitsComponent,
  },
  // metaInfo() {
  //   return {
  //     title: this.data.meta_title || this.data.title,
  //     description: this.data.meta_description,
  //     keywords: this.data.meta_keywords,
  //   };
  // },
};
</script>

<style lang="stylus">
.share-page {
  &__img {
    width 100%
    height auto
    border-radius var(--big-radius)
    overflow hidden
  }

  &__description {
    font-size: 1.125em
    line-height: 1.55
    max-width 800px
    +below(540px) {
      font-size 1em
    }
  }

  &__body {
    display grid
    grid-template-columns minmax(280px, 320fr) 1042fr
    grid-gap var(--gap)
    align-items start
    +below(990px) {
      grid-template-columns 1fr
    }
  }

  &__products-list {
    display flex
    flex-direction column
    gap: 40px
  }

  &__filter-mobile {
    +above(991px) {
      display none
    }
    +below(480px) {
      width 100%
    }
  }
}
</style>
